// Bootstrap variables
$link-color: #337ab7;
$primary: #5cb85c;
$table-hover-bg: rgba(#000, .025);
$btn-transition: none;
$body-color: #191414;
$input-btn-focus-box-shadow: none;
$input-placeholder-color: #dee2e6;

@import "~bootstrap/scss/bootstrap";


// Small button styles
$btn-padding-x-xs:  .35rem !default;
$btn-padding-y-xs:  .12rem !default;
$input-btn-line-height-xs: 1.3 !default;

.btn.btn-xs {
   // line-height: ensure proper height of button next to small input
   @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $font-size-sm, $input-btn-line-height-xs, $btn-border-radius-sm);
}
